import React from "react"
import Layout from "../components/layout"

import "../styles/styles.scss"

const Page = () => (
  <Layout>   
    <h1>Something goes wrong</h1>
    
  </Layout>
);

export default Page;